import React, { useState, useRef } from 'react'
import useOnClickOutside from '../hooks/useOnClickOutside'

// SVGs
import Indicator from './svg/indicator'
import Logout from './svg/logout'
import User from './svg/user'

const BidJSMenu = ({ navStyles, subNavStyles }) => {
  const ref = useRef()
  useOnClickOutside(ref, () => setAccountOpen(false))

  const [isAccountOpen, setAccountOpen] = useState(false)
  const accountToggle = () => setAccountOpen(!isAccountOpen)

  return (
    <li ref={ref} className='x-bidlogix--authenticated-show relative ml-auto hidden w-auto text-left'>
      <button aria-expanded={isAccountOpen} aria-haspopup='true' className={navStyles} onClick={accountToggle} onKeyDown={accountToggle}>
        <User className='mr-3 w-5 border-gray-300 bg-gray-50' />
        My Account
        <div className='ml-auto'>
          <Indicator className='w-6' />
        </div>
      </button>
      <ul className={`${isAccountOpen ? 'flex min-w-max flex-col bg-gray-50 md:absolute md:right-0 md:mt-2 md:rounded md:p-3 md:shadow-md' : 'hidden'}`}>
        <li key='settings'>
          <a href='/auction/#!/account' className={subNavStyles} onClick={() => accountToggle()}>
            My Account
          </a>
        </li>
        <li key='invoice'>
          <a href='/auction/#!/invoices' className={subNavStyles} onClick={() => accountToggle()}>
            My Invoices
          </a>
        </li>
        <li key='auctionsArchived'>
          <a className={subNavStyles} href='../auction/#!/auctionsArchived' onClick={() => accountToggle()}>
            Archived Auctions
          </a>
        </li>
        <li key='admin' className='x-bidlogix--administrator-show hidden'>
          <a href='#' className={subNavStyles} target='_blank' rel='noopener noreferrer' onClick={() => accountToggle()}>
            Admin
          </a>
        </li>

        <li key='logout' className='border-t pt-2'>
          <a href='/auction/#!/logout' className={`items-center justify-between ${subNavStyles}`} onClick={() => accountToggle()}>
            Logout
            <Logout className='h-5 w-5' />
          </a>
        </li>
      </ul>
    </li>
  )
}

export default BidJSMenu
