import React, { useState } from 'react'
import { Fade as Hamburger } from 'hamburger-react'
import { graphql, Link, StaticQuery } from 'gatsby'
import { PrismicLink } from '@prismicio/react'
import { GatsbyImage } from 'gatsby-plugin-image'

// Components
import BidJSMenu from './bidjsMenu'
import Dropdown from './dropdown'

const headerQuery = graphql`
  {
    prismicNavigation(lang: { eq: "en-gb" }) {
      ...PrismicNavigationFragment
    }
    prismicConfiguration(lang: { eq: "en-gb" }) {
      ...PrismicConfigurationFragment
    }
  }
`

const Header = (props) => {
  const [isAccountOpen, setAccountOpen] = useState(false)
  const [isOpen, setOpen] = useState(false)

  const accountToggle = () => setAccountOpen(!isAccountOpen)

  let navStyles =
    'flex w-full flex-row justify-center lg:justify-between uppercase items-center p-4 lg:py-2 font-medium text-brand-primary transition duration-150 ease-in-out hover:text-gray-600 focus:text-brand-secondary'
  let subNavStyles = 'flex uppercase justify-center lg:justify-between p-4 lg:py-2 font-medium text-gray-700 transition duration-150 ease-in-out hover:text-gray-800 focus:text-gray-900'

  return (
    <StaticQuery
      query={`${headerQuery}`}
      render={(data) => {
        const headerData = data.prismicNavigation.data
        const config = data.prismicConfiguration.data

        return (
          <header role='banner' className='relative z-20 bg-white'>
            <nav className='relative mx-auto flex max-w-screen-2xl items-center gap-10 px-6 py-4'>
              <Link to='/' className='z-20 inline-block'>
                <GatsbyImage className='w-60 object-contain' image={config.company_logo.gatsbyImageData} alt={config.company_display_name} />
                <span className='sr-only'>Homepage</span>
              </Link>
              <span className='z-20 ml-auto lg:hidden'>
                <Hamburger color='#014a7f' label='Show menu' rounded toggled={isOpen} toggle={setOpen} />
              </span>
              <div
                className={`${
                  !isOpen ? 'tw-hidden' : 'fixed inset-0 z-10 flex h-screen flex-1 flex-col justify-start bg-black bg-opacity-25 p-2'
                } lg:relative lg:flex lg:h-auto lg:w-full lg:flex-row lg:bg-transparent`}>
                <div className='max-h-screen w-full overflow-y-scroll rounded bg-white shadow-lg lg:max-h-none lg:overflow-visible lg:rounded-none lg:bg-transparent lg:shadow-none'>
                  <ul className='relative w-full flex-row items-center pb-4 pt-20 lg:flex lg:py-0'>
                    {headerData.nav.map((nav, index) => {
                      return (
                        <React.Fragment key={`nav-${index}`}>
                          {nav.items.length > 1 ? (
                            <Dropdown items={nav.items} navStyles={navStyles} subNavStyles={subNavStyles}>
                              {nav.primary.label}
                            </Dropdown>
                          ) : (
                            <li key={`nav-${index}`}>
                              <PrismicLink className={navStyles} field={nav.primary.link}>
                                {nav.primary.label}
                              </PrismicLink>
                            </li>
                          )}
                        </React.Fragment>
                      )
                    })}
                    <li className='x-bidlogix--authenticated-hide ml-auto'>
                      <PrismicLink href='/auction/#!/login' className='button w-full text-center' onClick={() => accountToggle()}>
                        Log In / Register
                      </PrismicLink>
                    </li>
                    <BidJSMenu navStyles={navStyles} subNavStyles={subNavStyles} />
                  </ul>
                </div>
              </div>
            </nav>
          </header>
        )
      }}
    />
  )
}

export default Header
