import React from 'react'
import { Script } from 'gatsby'

const Auction = () => {
  return (
    <section className='component-auction mx-auto w-full max-w-screen-2xl px-6 py-10'>
      <Script
        id='bid-js-config'
        strategy='idle'
        dangerouslySetInnerHTML={{
          __html: `
            window.bidjs = {
              config: {
                clientId: '${process.env.GATSBY_BIDJS_CLIENT_ID}',
                googleMapsApiKey: '${process.env.GATSBY_GOOGLE_API_KEY}',
                region: '${process.env.GATSBY_BIDJS_REGION}',
                server: '${process.env.GATSBY_BIDJS_SERVER}',
              },
              options: {
                allowMetaDescriptionChange: true,
                allowTitleChange: true,
                analyticsCode: '${process.env.GATSBY_COMPANY_GA}',
                analyticsNamespace: 'app',
                disableBranding: true,
                enablePaginationTop: true,
                itemsPerPageDefault: 18,
                scrollOnNavigationElementId: '___gatsby',
                searchPlaceholder: 'Search',
                standalone: false,
                unsupportedBrowserMessage: 'Your browser is not supported. Please use a browser such as Google Chrome or Microsoft Edge to ensure full functionality',
              },
              modules: {
                invoices: true,
                lotDetails: '/auction/#!/auctions/%AUCTION_ID%/listings/%ITEM_ID%',
                marketplaceLotDetails: '/auctions/#!/marketplace/listings/%ITEM_ID%'
              },
              callback: function (event) {
                switch (event.action) {
                  case 'BIDJS_NAVIGATED':
                  case 'BIDJS_MODULES_NAVIGATED':
                    if (window.toggleNavState) {
                      window.toggleNavState(event.data.path.split('/')[1].replace('Secure?',''))
                    }
                  break
                }
              }
            }
          `
        }}
      />
      <div className='bidlogix-app bidjs-app'>
        <div id='bidlogix-modal' />
      </div>
      <div className='bidlogix-app'>
        <div id='bidjs' className='bidjs-app' />
      </div>
    </section>
  )
}

export default React.memo(Auction)
