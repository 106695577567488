import React from 'react'
import { Script } from 'gatsby'

const BidJSScripts = ({ uid }) => {
  console.log(uid)
  return (
    <>
      <link href={`https://static.${process.env.GATSBY_BIDJS_DOMAIN}/${process.env.GATSBY_BIDJS_VERSION}/bootstrap3/css/bidjs--full.min.css`} rel='stylesheet' type='text/css' />
      <Script id='bid-js-script' strategy='idle' src={`https://static.${process.env.GATSBY_BIDJS_DOMAIN}/${process.env.GATSBY_BIDJS_VERSION}/bootstrap3/js/bidjs.min.js`} />
      {uid !== 'auction' && (
        <>
          <Script
            id='bid-js-config-default'
            dangerouslySetInnerHTML={{
              __html: `
              window.bidjs = {
                config: {
                  clientId: '${process.env.GATSBY_BIDJS_CLIENT_ID}',
                  googleMapsApiKey: '${process.env.GATSBY_BIDJS_GOOGLE_API_KEY}',
                  region: '${process.env.GATSBY_BIDJS_REGION}',
                  server: '${process.env.GATSBY_BIDJS_SERVER}',
                },
                options: {
                  allowTitleChange: false,
                  allowMetaDescriptionChange: false,
                  analyticsCode: '${process.env.GATSBY_COMPANY_GA}',
                  analyticsNamespace: 'app',
                  defaultModule: 'empty',
                  disableBranding: true,
                  enablePaginationTop: true,
                  scrollOnNavigationElementId: '___gatsby',
                  standalone: false,
                  unsupportedBrowserMessage: 'Your browser is not supported. Please use a browser such as Google Chrome or Microsoft Edge to ensure full functionality',
                  urlPattern: 'pushState',
                },
                modules: {
                  auctionDetails: false,
                  auctionsArchived: false,
                  invoices: false,
                  lotDetails: false,
                  mySales: false,
                  webcast: false,
                  login: false,
                  register: false
                }
              }
            `
            }}
          />
          <div className='bidlogix-app bidjs-app hidden'>
            <div id='bidlogix-modal' />
          </div>
          <div className='bidlogix-app hidden'>
            <div id='bidjs' className='bidjs-app relative' />
          </div>
        </>
      )}
    </>
  )
}

export default BidJSScripts
